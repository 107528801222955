<template>
  <div style="width: 100%; height: 100%; padding-left: 39px; padding-right: 39px; padding-top: 35px; padding-bottom: 35px; background: #F6FAFF; border-radius: 20px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: inline-flex">
    <div style="align-self: stretch; padding-top: 6px; padding-bottom: 6px; background: #F72585; border-radius: 4px; justify-content: center; align-items: center; gap: 8px; display: inline-flex">
      <div style="width: 397px; color: white; font-size: 34px; font-family: Rubik; font-weight: 500; line-height: 36px; word-wrap: break-word">Fórmulas de Producción</div>
    </div>
    <v-row style="width: 100%;">
      <v-col cols="12" sm="6">
        <div style="gap: 4px;">
          <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Nombre del producto</div>
          <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
        </div>
        <v-text-field rounded background-color="#EBF1FF" dense flat filled solo class="text-body-2" value="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <div style="align-self: stretch; gap: 4px;">
          <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Código CABYS</div>
          <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
        </div>
        <v-autocomplete rounded background-color="#EBF1FF" :items="cabys" item-value="code" item-text="description" dense flat filled solo class="text-body-2"></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <div style="align-self: stretch; gap: 4px;">
          <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Código interno</div>
          <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
        </div>
        <v-text-field rounded background-color="#EBF1FF" dense flat filled solo class="text-body-2" value="A123456789"></v-text-field>
      </v-col>
    </v-row>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
        <div style="color: #480CA8; font-size: 20px; font-family: Rubik; font-weight: 500; line-height: 24px; letter-spacing: 0.15px; word-wrap: break-word">Selecciona los materiales que necesitaras</div>
    </div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 30px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Selecciona  el material</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
              <v-autocomplete rounded background-color="#EBF1FF" :items="cabys" item-value="commercialCode" item-text="description" dense flat filled solo class="text-body-2"></v-autocomplete>
                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
                    <div style="width: 218px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; background: #E7EEFE; border-radius: 8px; justify-content: flex-start; align-items: center; gap: 6px; display: inline-flex">
                        <div style="text-align: center; color: #F72585; font-size: 20px; font-family: Rubik; font-weight: 400; line-height: 16px; word-wrap: break-word">+</div>
                        <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Crear materia prima</div>
                    </div>
                    <div style="width: 218px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; background: #E7EEFE; border-radius: 8px; justify-content: flex-start; align-items: center; display: inline-flex">
                        <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material para selección 1</div>
                    </div>
                    <div style="width: 218px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; background: #E7EEFE; border-radius: 8px; justify-content: flex-start; align-items: center; display: inline-flex">
                        <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material para selección 2</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Cantidad</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <v-text-field type="number" rounded background-color="#EBF1FF" dense flat filled solo class="text-body-2 text-left" value="2"></v-text-field>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Unidad de medida</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <v-select rounded background-color="#EBF1FF" :items="units" dense flat filled solo class="text-body-2"></v-select>
        </div>
    </div>
    <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
        <div style="width: 12px; height: 12px; position: relative">
            <div style="width: 12px; height: 12px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 7.20px; height: 7.20px; left: 2.40px; top: 2.40px; position: absolute; background: white"></div>
        </div>
        <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Añadir material</div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Materiales seleccionados</div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">2</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material seleccionado 1</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">2</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material  seleccionado 2</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">2</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material seleccionado 3</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
        <div style="color: #3F37C9; font-size: 20px; font-family: Rubik; font-weight: 500; line-height: 24px; letter-spacing: 0.15px; word-wrap: break-word">Crea los pasos requeridos</div>
    </div>
    <div style="justify-content: flex-start; align-items: center; gap: 30px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Nombre del paso</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: inline-flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Duracion</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="width: 58px; height: 23px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: inline-flex">
                <div style="width: 26px; text-align: center; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">4</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Unidad de medida</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 101.68px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Seleccione la unidad</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
    </div>
    <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: flex">
        <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Descripción del paso</div>
            <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
        </div>
        <div style="width: 516px; height: 23px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: inline-flex">
            <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
        </div>
    </div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 20px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Departamento encargado</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 139.68px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Seleccione el departamento</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Agregar responsable</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 139.68px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Seleccione el departamento</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
        <div style="width: 12px; height: 12px; position: relative">
            <div style="width: 12px; height: 12px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 7.20px; height: 7.20px; left: 2.40px; top: 2.40px; position: absolute; background: white"></div>
        </div>
        <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Crear paso</div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Pasos</div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">1</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material seleccionado 1</div>
                </div>
            </div>
            <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Departamento</div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
                <div style="width: 15px; height: 15px; position: relative">
                    <div style="width: 6.87px; height: 11.25px; left: 4.06px; top: 1.88px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">2</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material  seleccionado 2</div>
                </div>
            </div>
            <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Departamento</div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
                <div style="width: 15px; height: 15px; position: relative">
                    <div style="width: 6.87px; height: 11.25px; left: 4.06px; top: 1.88px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">3</div>
                </div>
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Material seleccionado 3</div>
                </div>
            </div>
            <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Departamento</div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
                <div style="width: 15px; height: 15px; position: relative">
                    <div style="width: 6.87px; height: 11.25px; left: 4.06px; top: 1.88px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
        <div style="color: #3F37C9; font-size: 20px; font-family: Rubik; font-weight: 500; line-height: 24px; letter-spacing: 0.15px; word-wrap: break-word">Crea tu formulario personalizado</div>
    </div>
    <div style="justify-content: flex-start; align-items: center; gap: 30px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Nombre del campo</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: inline-flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Selecciona la funcion</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 101.68px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Lista desplegable</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="padding-top: 4px; flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="width: 60px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                    <div style="align-self: stretch; color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Obligatorio</div>
                </div>
            </div>
            <div style="width: 30.07px; height: 16.40px; padding-top: 1.37px; padding-bottom: 1.37px; padding-left: 1.37px; padding-right: 15.03px; background: #535763; border-radius: 68.34px; justify-content: flex-start; align-items: center; display: inline-flex">
                <div style="width: 13.67px; height: 13.67px; position: relative; background: white; box-shadow: 0px 1.3667755126953125px 2.733551025390625px rgba(39, 39, 39, 0.10); border-radius: 68.34px"></div>
            </div>
        </div>
    </div>
    <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: center; gap: 30px; display: inline-flex">
            <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                    <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Opciones</div>
                </div>
            </div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18.05px; height: 18.05px; border-radius: 9999px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
            </div>
            <div style="width: 12px; height: 12px; transform: rotate(45deg); transform-origin: 0 0; background: #4361EE"></div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18.05px; height: 18.05px; border-radius: 9999px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
            </div>
            <div style="width: 12px; height: 12px; transform: rotate(45deg); transform-origin: 0 0; background: #4361EE"></div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18.05px; height: 18.05px; border-radius: 9999px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Añadir opción</div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="justify-content: flex-start; align-items: center; gap: 30px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Nombre del campo</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: inline-flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Selecciona la funcion</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 101.68px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Check box</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="padding-top: 4px; flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="width: 60px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                    <div style="align-self: stretch; color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Obligatorio</div>
                </div>
            </div>
            <div style="width: 30.07px; height: 16.40px; padding-top: 1.37px; padding-bottom: 1.37px; padding-left: 15.03px; padding-right: 1.37px; background: #3F37C9; border-radius: 68.34px; justify-content: flex-end; align-items: center; display: inline-flex">
                <div style="width: 13.67px; height: 13.67px; position: relative; background: white; box-shadow: 0px 1.3667755126953125px 2.733551025390625px rgba(39, 39, 39, 0.10); border-radius: 68.34px"></div>
            </div>
        </div>
    </div>
    <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: center; gap: 30px; display: inline-flex">
            <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                    <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Opciones</div>
                </div>
            </div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18px; height: 18px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Opción 1</div>
            </div>
            <div style="width: 12px; height: 12px; transform: rotate(45deg); transform-origin: 0 0; background: #4361EE"></div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18px; height: 18px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Opción 2</div>
            </div>
            <div style="width: 12px; height: 12px; transform: rotate(45deg); transform-origin: 0 0; background: #4361EE"></div>
        </div>
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
            <div style="width: 18px; height: 18px; border: 2px #4361EE solid"></div>
            <div style="width: 232.54px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; display: flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Añadir opción</div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 20px; display: inline-flex">
        <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #7209B7; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
            <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Vista previa</div>
        </div>
        <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
            <div style="width: 12px; height: 12px; position: relative">
                <div style="width: 12px; height: 12px; left: 0px; top: 0px; position: absolute; background: white"></div>
                <div style="width: 7.20px; height: 7.20px; left: 2.40px; top: 2.40px; position: absolute; background: white"></div>
            </div>
            <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Crear nueva pregunta</div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
        <div style="color: #3F37C9; font-size: 20px; font-family: Rubik; font-weight: 500; line-height: 24px; letter-spacing: 0.15px; word-wrap: break-word">Agrega los precios</div>
    </div>
    <div style="justify-content: flex-start; align-items: center; display: inline-flex">
        <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #7209B7; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
            <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Precios fijos</div>
        </div>
        <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #EBF1FF; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
            <div style="text-align: center; color: #4361EE; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Precios calculados</div>
        </div>
    </div>
    <div style="width: 24px; height: 24px; position: relative">
        <div style="width: 14.40px; height: 15.31px; left: 5.37px; top: 4.39px; position: absolute">
            <div style="width: 14.40px; height: 15.31px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 14.40px; height: 15.31px; left: 0px; top: 0px; position: absolute; border: 0.75px black solid"></div>
            <div style="width: 0.75px; height: 4.20px; left: 10.63px; top: 8.61px; position: absolute; background: black"></div>
            <div style="width: 0.77px; height: 4.20px; left: 8.63px; top: 8.61px; position: absolute; background: black"></div>
            <div style="width: 0.77px; height: 4.20px; left: 6.63px; top: 8.61px; position: absolute; background: black"></div>
        </div>
    </div>
    <div style="justify-content: flex-start; align-items: flex-end; gap: 24px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Categoría de precio</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 116.80px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Seleccione la categoría</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Valor</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="width: 128.65px; height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; display: inline-flex">
                <div style="flex: 1 1 0; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">0000000000000</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Moneda</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-top: 6px; padding-bottom: 6px; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; gap: 6px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 4px; display: flex">
                    <div style="width: 15px; height: 15px; border-radius: 9999px; border: 2px #4361EE solid"></div>
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Colones</div>
                </div>
                <div style="justify-content: flex-start; align-items: center; gap: 4px; display: flex">
                    <div style="width: 15px; height: 15px; border-radius: 9999px; border: 2px #4361EE solid"></div>
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Dolares</div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
        <div style="width: 12px; height: 12px; position: relative">
            <div style="width: 12px; height: 12px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 7.20px; height: 7.20px; left: 2.40px; top: 2.40px; position: absolute; background: white"></div>
        </div>
        <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Añadir nuevo precio</div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Precios establecidos</div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Detalle</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢2 000</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Mayorista</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢1 500</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Especial</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢1 000</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
        <div style="color: #3F37C9; font-size: 20px; font-family: Rubik; font-weight: 500; line-height: 24px; letter-spacing: 0.15px; word-wrap: break-word">Agrega los precios</div>
    </div>
    <div style="justify-content: flex-start; align-items: center; gap: 24px; display: inline-flex">
        <div style="justify-content: flex-start; align-items: center; display: flex">
            <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #EBF1FF; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 104px; text-align: center; color: #4361EE; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Precios fijos</div>
            </div>
            <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #7209B7; border-top-left-radius: 8px; border-top-right-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Precios calculados</div>
            </div>
        </div>
    </div>
    <div style="width: 24px; height: 24px; position: relative">
        <div style="width: 14.40px; height: 15.31px; left: 5.37px; top: 4.39px; position: absolute">
            <div style="width: 14.40px; height: 15.31px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 14.40px; height: 15.31px; left: 0px; top: 0px; position: absolute; border: 0.75px black solid"></div>
            <div style="width: 0.75px; height: 4.20px; left: 10.63px; top: 8.61px; position: absolute; background: black"></div>
            <div style="width: 0.77px; height: 4.20px; left: 8.63px; top: 8.61px; position: absolute; background: black"></div>
            <div style="width: 0.77px; height: 4.20px; left: 6.63px; top: 8.61px; position: absolute; background: black"></div>
        </div>
    </div>
    <div style="width: 303.67px; color: black; font-size: 12px; font-family: Roboto; font-weight: 400; line-height: 16px; letter-spacing: 0.40px; word-wrap: break-word">El precio calculado es igual al costo calculado por el sistema mas el margen deseado.</div>
    <div style="justify-content: flex-start; align-items: center; gap: 24px; display: inline-flex">
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Categoría de precio</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
                <div style="width: 116.80px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">Seleccione la categoría</div>
                <div style="width: 16px; height: 16px; position: relative; transform: rotate(180deg); transform-origin: 0 0">
                    <div style="width: 8px; height: 4.57px; left: 4px; top: 5.71px; position: absolute; background: #4361EE"></div>
                </div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Margen</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 4px; display: inline-flex">
                <div style="width: 57.25px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: #EBF1FF; border-radius: 8px; overflow: hidden; justify-content: center; align-items: center; display: flex">
                    <div style="width: 25.25px; color: black; font-size: 11px; font-family: Roboto; font-weight: 400; word-wrap: break-word">80</div>
                </div>
                <div style="color: black; font-size: 14px; font-family: Roboto; font-weight: 400; word-wrap: break-word">%</div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Moneda</div>
                <div style="color: #F72585; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">*</div>
            </div>
            <div style="height: 22.66px; padding-top: 6px; padding-bottom: 6px; border-radius: 8px; overflow: hidden; justify-content: flex-start; align-items: center; gap: 6px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 4px; display: flex">
                    <div style="width: 15px; height: 15px; position: relative">
                        <div style="width: 15px; height: 15px; left: 0px; top: 0px; position: absolute; border-radius: 9999px; border: 2px #4361EE solid"></div>
                        <div style="width: 7.89px; height: 7.89px; left: 3.56px; top: 3.56px; position: absolute; background: #4361EE; border-radius: 9999px"></div>
                    </div>
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Colones</div>
                </div>
                <div style="justify-content: flex-start; align-items: center; gap: 4px; display: flex">
                    <div style="width: 15px; height: 15px; border-radius: 9999px; border: 2px #4361EE solid"></div>
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Dolares</div>
                </div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex">
            <div style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 2px; display: inline-flex">
                <div style="width: 60px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                    <div style="align-self: stretch; color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Redondear</div>
                </div>
                <div style="width: 8.44px; height: 8.44px; background: #4361EE"></div>
            </div>
            <div style="width: 30.07px; height: 16.40px; padding-top: 1.37px; padding-bottom: 1.37px; padding-left: 15.03px; padding-right: 1.37px; background: #3F37C9; border-radius: 68.34px; justify-content: flex-end; align-items: center; display: inline-flex">
                <div style="width: 13.67px; height: 13.67px; position: relative; background: white; box-shadow: 0px 1.3667755126953125px 2.733551025390625px rgba(39, 39, 39, 0.10); border-radius: 68.34px"></div>
            </div>
        </div>
    </div>
    <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
        <div style="width: 12px; height: 12px; position: relative">
            <div style="width: 12px; height: 12px; left: 0px; top: 0px; position: absolute; background: white"></div>
            <div style="width: 7.20px; height: 7.20px; left: 2.40px; top: 2.40px; position: absolute; background: white"></div>
        </div>
        <div style="text-align: center; color: white; font-size: 12px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Añadir nuevo precio</div>
    </div>
    <div style="width: 100%; height: 0px; border: 1px rgba(67, 97, 238, 0.50) solid"></div>
    <div style="flex-direction: column; justify-content: center; align-items: flex-start; gap: 6px; display: flex">
        <div style="justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
            <div style="color: #3F37C9; font-size: 12px; font-family: Roboto; font-weight: 500; word-wrap: break-word">Precios establecidos</div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Detalle</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢2 000</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Mayorista</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢1 500</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="border-radius: 6px; border: 0.50px #535763 solid; justify-content: flex-start; align-items: center; gap: 26px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 6px; display: flex">
                <div style="height: 24px; padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">Especial</div>
                </div>
                <div style="padding-left: 12px; padding-right: 12px; padding-top: 4px; padding-bottom: 4px; border-radius: 8px; justify-content: flex-start; align-items: center; display: flex">
                    <div style="color: #535763; font-size: 11px; font-family: Roboto; font-weight: 400; line-height: 16px; word-wrap: break-word">¢1 000</div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 6px; display: flex">
                <div style="width: 16px; height: 16px; position: relative">
                    <div style="width: 12.67px; height: 12.67px; left: 2px; top: 1.33px; position: absolute; background: #4361EE"></div>
                </div>
                <div style="width: 20px; height: 20px; position: relative">
                    <div style="width: 15px; left: 2.50px; top: 1.67px; position: absolute">
                        <div style="width: 0.70px; height: 0.56px; left: 7.56px; top: 17.62px; position: absolute"></div>
                        <div style="width: 15px; height: 16.67px; left: 0px; top: 0px; position: absolute; background: #4361EE"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%; height: 0px; border: 2px #F72585 solid"></div>
    <div style="padding-left: 14px; padding-right: 14px; padding-top: 8px; padding-bottom: 8px; background: #F72585; border-radius: 21.29px; overflow: hidden; justify-content: center; align-items: center; gap: 6px; display: inline-flex">
        <div style="text-align: center; color: white; font-size: 16px; font-family: Roboto; font-weight: 500; line-height: 12.66px; letter-spacing: 0.12px; word-wrap: break-word">Guardar</div>
    </div>
  </div>
</template>
<script type="text/javascript">
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import { mapMutations, mapActions, mapGetters } from 'vuex'
import * as cabys from '@/api/cabys.json'
import { units } from '@/assets/codes.js'

export default {
  name: 'ProductionFormula',
  data: () => ({
    cabys: cabys.default.data,
    units
  }),
  mounted () {
  },
  watch: {
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style>
input:focus {
  border:none;
}
</style>
