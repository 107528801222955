var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getProducts,"sort-by":"name","search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Productos inventariables")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ImportData',{attrs:{"name":"products","humanName":"productos"}}),_c('PFNewDialog')],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.taxes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taxes && item.taxes.length ? Number(item.taxes[0].rate) : 0)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(((item.prices.findIndex( function (element) {return element.priceList == _vm.priceList}) > -1) ? Number( item.prices[item.prices.findIndex( function (element) {return element.priceList == _vm.priceList})].price ) + ( item.prices[item.prices.findIndex( function (element) {return element.priceList == _vm.priceList})].price * (item.taxes && item.taxes.length > 0 ? item.taxes[0].rate/100 : 0) ) : "No definido"),(item.prices.findIndex( function (element) {return element.priceList == _vm.priceList}) > -1) ? item.prices[item.prices.findIndex( function (element) {return element.priceList == _vm.priceList})].currency : null))+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.prices.findIndex( function (element) {return element.priceList == _vm.priceList}) > -1) ? item.prices[item.prices.findIndex( function (element) {return element.priceList == _vm.priceList})].currency : "No definida")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }